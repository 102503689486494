import '../styles/Jsonfield.css'
import { useState } from 'react'
import Aggregator from './Aggregator'

function Jsonfield({setWholeDatasMode, translate, lang, name, id, type, generictype, setFilter, setFilterType, viewFields, setViewFields, clickedJzonviz, setClicked, func, setAggregator, setLoading, setFilterValue
						, handleRemoveParameter, filtername, handleAddParameter, searchParams}){

	const [configMode, setConfigMode] = useState(false)
	const [defaultAggregator, setDefaultAggregator] = useState(searchParams.get('agg') || 'count')

	const configText = configMode ? translate(22) : translate(23)+'«' + name +'»'
	const assignFieldToViz = (e) => {
		
		//setLoading(true)
		e.preventDefault();
		e.stopPropagation();

		var temp = {}

		//empty json 
		if(Object.keys(clickedJzonviz).length === 0){
			temp = {
				[generictype]: id,
			}

		//non empty json => add or replace the type (dimension, measure) with a new value
		}else{
			temp = {
			    ...clickedJzonviz
			};
			temp[generictype] = id
		}

		//console.log(temp)
		setClicked(temp)

		handleAddParameter('dim',temp[translate(6)[1]] || '' )
		handleAddParameter('num',temp[translate(6)[4]] || '' )

		hideSideBarOnMobile(setViewFields)
		if( chosen({translate, clickedJzonviz, name}) ) {
			setAggregator(defaultAggregator) 
		}
		//console.log({defaultAggregator})

		nomoreWholeDatas()

	}


	const nomoreWholeDatas = () => {
		setWholeDatasMode(false)

		//remove "show all" param 
		handleRemoveParameter('show_all')
	}

	const updateMyLocalDefaultAgg = (e) => {
										setDefaultAggregator(e.target.value) //do it locally
										if( chosen({translate, clickedJzonviz, name}) ){
											setAggregator(e.target.value) //spread the information IF THIS JSONFIELD IS CHOSEN	 
										} 
									}
	const handleNewFiltername = () => {

		
		//remove any previous filter (if exists)
		if(filtername){
			console.log('removing',filtername)
			handleRemoveParameter('filter['+filtername+']')
		}


		//no value yet, just filtername
		setFilter(id);
		setFilterType(type)

		handleAddParameter('filter['+id+']','',true)
		
		hideSideBarOnMobile(setViewFields)
		
	}

	const idTypeField = generictype === translate(6)[1] ? '20dim'
						: generictype === translate(6)[4] ? '20num'
						: ""

	return <div  className="jsonfield-wrap">

			{!configMode &&
				<div title={translate(19)+name+translate(20)+translate(idTypeField)}
							draggable="true"
							type={type}
							generictype={generictype}
							className="jsonfield"
							id={id}
							key={id}
							func={func}
							onClick={assignFieldToViz}
			
				><span>{name}</span>

				</div>
			}

			<div>
				{type !== 'Calculs' &&
				<div className="config-wrap">
					{!configMode &&
						<button className="btn-jsonfield-filter" title={translate(21)+ '«' + name +'»'} onClick={(e) => {

							e.preventDefault();
							e.stopPropagation();

							handleNewFiltername()

						}}></button>
					}
					{configMode &&
						<div>
							<h4 className="setup-num">{translate(24)}<strong>{name}</strong></h4>
							<Aggregator translate={translate} fromSidebar={true} defaultValue={defaultAggregator} onChange={updateMyLocalDefaultAgg}/>
						</div>
					}
					{type === translate(6)[4] && <button onClick={() => {setConfigMode(!configMode)}} className="btn-jsonfield-gear" configmode={configMode.toString()} title={configText}></button>}
				</div>
				}
			</div>

			</div>

}

function chosen({translate, clickedJzonviz, name}){
	//console.log({clickedJzonviz})

	// 4 => numbers
	// 1 => dimensions
	const translated = translate(6)
	//console.log({translated})

	const chosenvalues = Object.keys(clickedJzonviz).length > 0 ? (clickedJzonviz[translated[4]] || clickedJzonviz[translated[1]]) : ''
	return chosenvalues.includes(name)
}


function hideSideBarOnMobile(setViewFields){

	document.querySelector('#toggleSidebar').checked = false //hide side bar
	setViewFields(false) //show vizz
}


export default Jsonfield