import { useState, useEffect } from 'react'
import Apis from './Apis'
import Post from './Post'
import '../styles/Datasource.css'

function Datasource({ lang, translate, defaultPostValue, postDatas, setPostDatas, showPostDatasMode, setShowPostDatas, defaultURL, encode_str, currentURL, setURLsource, isValid, setValid, datas, updateDatas, currentlyLoading, setLoading, reinit_view, 
	                      handleAddParameter,
	                      handleRemoveParameter,
	                      searchParams,
	                      setSearchParams,
	                      location,
	                      setCurrentNav
					}){
	
	//console.log('from datasource', {defaultURL})

	const [localURL, setLocalUrl] = useState('')
  	const [notice, updateNotice] = useState('')
	const [clicked, setClickedStart] = useState(false)
	const [chooseAPImode, setChoicemode] = useState(false)
	const askUserChoice = translate('keyChoice')
	

	const handleNoUrlSource = () => {
		setLocalUrl('')
		setSearchParams(new URLSearchParams())

		updateDatas([]) //remove all previous datas

		//go back to import
		setCurrentNav('nav_Import')
	}

	const handleNewURL = () => {
		setClickedStart(true)
		handleAddParameter('url', localURL) // change url params											
	}


	const fetchDatas = async () => {
		setLoading(true);
	    if ((!currentURL && !defaultURL)) { 
	        setLoading(false);
	        return setLocalUrl(''); 
	    }

	    //no clicked found AND no forcing => do nothing
	    if(clicked===false && !(searchParams.get('nav') === "viz")) return setLoading(false); 

	    // if already fetching -> do nothing
	    if (currentlyLoading) return false;

	    //after everything... let's go fetching

	    updateDatas([]);
	    
	    const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(new Error(translate('timeout'))), ms));
	    const fetchWithTimeout = async (url, options) => {
	        return Promise.race([
	            fetch(url, options).then(response => response.text()),
	            timeout(120000) // 120 seconds timeout
	        ]);
	    };

	    try {
	        const finalURLtofetch = currentURL ? currentURL : defaultURL;
	        let json_fetched;

	        // post mode
	        if (showPostDatasMode) {
	            const fetched = await fetchWithTimeout(finalURLtofetch, { method: 'POST', body: postDatas });
	            if (fetched.length > 0) {
	                json_fetched = JSON.parse(fetched);

	                json_fetched = mainTransform(json_fetched, askUserChoice);
	                json_fetched = addRowNumber(json_fetched, translate);

	                updateDatas(json_fetched)
	                setCurrentNav('nav_Dataviz')
					handleAddParameter('nav','viz')
	            }
	        // NOT post mode
	        } else {
	            const fetched = await fetchWithTimeout(finalURLtofetch);
	            if (fetched.length > 0) {
	                json_fetched = JSON.parse(fetched);

	                json_fetched = mainTransform(json_fetched, askUserChoice);
	                json_fetched = addRowNumber(json_fetched, translate);

	                updateDatas(json_fetched);
					setCurrentNav('nav_Dataviz')
					handleAddParameter('nav','viz')
	            }
	        }
	    } catch (err) {
	        updateDatas([]);
	        console.log(err);
			setCurrentNav('nav_Import')
			
	        var finalerror = translate(3) + ' ' + (err.toString().includes('SyntaxError') ? translate('notJSON') : translate('cors'));
	        if (err.toString().includes('timeout')) {
	            finalerror = translate('timeout');
	        }
	        updateNotice(finalerror);
	    } finally {
	        setLoading(false);
			
	    }

	    setClickedStart(false);
	};

	useEffect(() => {
		setLocalUrl(defaultURL)

	}, [defaultURL])

	useEffect(() => {
		//console.log('clicked changed to',clicked)

		fetchDatas()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[clicked])

	useEffect(() => {
		updateDatas( addRowNumber(datas,translate) )

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang])

	// eslint-disable-next-line
	const storageToUse = () => {
		return document.getElementById('saveURL') && document.getElementById('saveURL').checked ? window.localStorage : window.sessionStorage
	}

	const handleOpenApi = (e) => {
		e.stopPropagation()
		e.preventDefault()
		setChoicemode(!chooseAPImode)
	}

	const handleInput = (val) => {

		setLocalUrl(val)
		updateNotice('')
		const nextURL = val
		setURLsource(nextURL)	
		const nextValid = isValidURL(nextURL)
		setValid(nextValid)


		if(nextValid){	
			//storageToUse().setItem('currentURL', encode_str(val))				
		}else{
			if(nextURL.length > 0) updateNotice(translate(1))
			//storageToUse().removeItem('currentURL')
		}

	}

	const handleUpdateAPI = (val) => {
		setLocalUrl(val)
		handleInput(val)
		setChoicemode(false)
		setClickedStart(true)
	}

	const handleCloseApi = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setChoicemode(false)
	}

	const handleNoAlert = () => {
		//console.log({notice})
		if(notice !== translate(2)) updateNotice('')
	}

	const handleReset = () => {
		
		//handleCloseApi()
		handleNoAlert()
	}

	return (<><div className="data-src-url"  onClick={handleReset} >
				<div className="data-src-wrapper"><div className="wrap-url">
									
									<div>
										<input  id="currentURL"
												className="url-input"
												value={localURL}
												type="text"
												placeholder={translate(0)}
												onKeyDown={(e) => {
													//console.log('key down',e)
													if (e.key === 'Enter') handleNewURL()
												}}
												onInput={(e) => handleInput(e.target.value)}
										/>

										{currentURL && localURL && <button className="reinit btn-url" onClick={() =>{handleNoUrlSource()}}></button>}


										{false && <div className="remember">
																				<input type="checkbox" id="saveURL" name="saveURL"  />
																				<label htmlFor="saveURL">{translate('0bis')}</label>
																			</div>}

									</div>


									<div className="go-wrapper">

										{false && <Post translate={translate}
												showPostDatasMode={showPostDatasMode}
												setShowPostDatas={setShowPostDatas}
												postDatas={postDatas}
												setPostDatas={setPostDatas}>
										</Post>}

										{

										/*DOCUMENTATION BUTTON HERE*/
										<a target="_blank" href="/docs">
											<button className="docs go"><span className="go-content">{translate('docs')}</span></button>
										</a>
										}

										{/*GO BUTTON HERE*/
										<button className="go" onClick={(e) =>{
											e.stopPropagation()
											e.preventDefault()
											if(localURL.length === 0){
												//console.log('empty')
												updateNotice(translate(2))		
												return false
											} 
											//console.log(localURL)
											handleNewURL()
											//reinit_view()

										}}><span className="go-content" id="start">{translate('start')}</span></button>}


										<div>{notice && <span className="url-notice" content={notice}>{notice}</span>}</div>
									</div>



									<>
										<h4 className="chooseAPI"  onClick={handleOpenApi}><span>{chooseAPImode ? translate('choiceAPI') : translate('openAPI')}</span></h4>
										{chooseAPImode && <div>
											<Apis setApi={handleUpdateAPI} handleCloseApi={handleCloseApi} 
								                      handleAddParameter={handleAddParameter}
												/>
										</div>}
									</>
								</div>
				
				</div>

			</div>
			</>
			)
}



export const addRowNumber = (datas,translate) => {
	var tmp = []
	datas.forEach(function(e,i){
		e[translate('rownumber')]=i+1
		tmp.push(e)
	})
	return tmp
}

function flattenObj(ob){
 
    // The object which contains the
    // final result
    let result = {};
 
    // loop through the object "ob"
    for (const i in ob) {
 
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
 
                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }
 
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};


function isValidURL(str){
  let url;
  
  try {
    url = new URL(str);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}






export function mainTransform(json_fetched, askUserChoice){
	const jsonOK = json_fetched && isJsonString(json_fetched)


	//it's already an array of objects WITH DATAS
	if(jsonOK){
		var res = firstArrayOfObjects(json_fetched)
		return res

	//do datas OR not an expected array
	} else {


		//not an array => flatten it
		if(!Array.isArray(json_fetched)){
			if(askUserChoice){

				const userChoice = prompt(askUserChoice, firstKeyWithArrays(json_fetched)) //get first key by default
				if(!userChoice){
					json_fetched = [flattenObj(json_fetched)]

				//user chose a specific key key
				} else {
					console.log('getting key "'+userChoice+'" from',{json_fetched})
					const all_selected_keys = userChoice.split('.')
					const temp_json_fetched = getNestedValue(json_fetched, all_selected_keys);

					return mainTransform(temp_json_fetched)
				}
			} else {
				json_fetched = [flattenObj(json_fetched)]
			}
			
			console.log({json_fetched})
		
		//already an array
		} else {
			console.log("should never be this case")
		}

		//return itself by default
		return json_fetched


	}

}

function getNestedValue(obj, keys) {
  return keys.reduce((acc, key) => acc && acc[key], obj);
}

function firstKeyWithArrays(obj) {
  // Helper function to determine if a value is an array
  function isArray(value) {
    return Array.isArray(value);
  }

  // Recursive function to find the first key with an array value
  function findKeyWithArray(o, path) {
    for (let key in o) {
      if (o.hasOwnProperty(key)) {
        const currentPath = path ? `${path}.${key}` : key;
        if (isArray(o[key])) {
          return currentPath;
        } else if (typeof o[key] === 'object' && o[key] !== null) {
          const result = findKeyWithArray(o[key], currentPath);
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  }

  // Call the recursive function and handle the case where no array is found
  const foundPath = findKeyWithArray(obj, '');
  return foundPath !== null ? foundPath : '';
}


function firstArrayOfObjects(fetcheddatas){
	
	
	// if 1 row : 
	if(fetcheddatas.length === 1){
		return treatObject(fetcheddatas, false)
	}

	return fetcheddatas
}


function treatObject(wholedatas, firstData){

		if(!firstData) firstData = wholedatas[0]
		//console.log('firstData',firstData)

		// IF IT IS AN OBJECT : check every key of first datas
		if(typeof(firstData) === 'object'){
			const allkeys = Object.keys(firstData)

			for (const key of allkeys){
				//console.log('\n\n\n\n\n')
				//console.log('key',key)

				//get value
				const value = firstData[key]
				//console.log('value',value)

				

				//if value is array -> return this
				if(Array.isArray(value)){
					//console.log('FOUND ! returning ',value)
					return value
				}else{
					wholedatas = treatObject(wholedatas, value)
				}

			}
		}

		return wholedatas
}


function isJsonString(str) {
	if(Array.isArray(str)) return true

	//console.log(str)
    try {
        JSON.parse(str.toString());
    } catch (e) {
        return false;
    }
    return true;
}






















export default Datasource