import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import App from './App';
import { translate } from './Translations'

const supabase = window.supabase // from common elements js

const Saved = ({id_viz_test}) => {
  const [vizData, setVizData] = useState(null);
  const [error, setError] = useState(null);

  // eslint-disable-next-line
  const id_viz = id_viz_test || useParams()['id_viz'] 


  //everytime id_viz changes => fetch new params of the viz
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from('jsonvizz')
          .select()
          .eq('id_viz', id_viz)
          .limit(1);

        if (!error && data) {
          setVizData(data[0]);
        } else {
          setError(error || 'No data found');
        }
      } catch (error) {
        setError('An error occurred while fetching data');
      }
    };

    fetchData();
  }, [id_viz]);

  if (error) {
    return <div>{id_viz + ' unrecognized.'}</div>;
  }

  if (!vizData) {
    return <div>{translate('loading')}</div>;
  }

  console.log({vizData})

  return (
    <App remoteParams={vizData['params']}/>
  );
};

export default Saved;
