import { useState, useEffect } from 'react'
import { isValidURL } from './Groupfield'
import { translate } from './Translations'
//import { Privatedatas } from './Privatedatas'

function Apis({setApi, handleCloseApi, handleAddParameter}){

	const [currentList, setList] = useState([]) 
	const [newURLmode, setNewURLMode] = useState(false) 

	const fetchURL = async (url) => {
		return await fetch(url)
				.then(response => response.text())
				.then(data => data)
	}
	
	const getRemote = async () => {
		return await fetch("https://jadynekena.github.io/open-apis/src.json?dummy=" + Date.now())
				.then(response => response.text())
				.then(data => JSON.parse(data).filter(e => e['url'].length > 0).reverse() /*only with urls + NEW first */)
				
	} 

	//const personal_apis = isLocalHost() ? Privatedatas : []


	const getDatas = async () => {
		var list_apis = []
		var remote = await getRemote()

		if(isLocalHost()){
			//list_apis = [...remote, ...personal_apis]
			list_apis = [...remote]
		}else{
			list_apis = [...remote]
		}

		return list_apis
	}


	useEffect(() => {

		getRemotelist()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	async function getRemotelist(){
		const alldatas = await getDatas()
		setList(alldatas)
	}

	const getValue = (obj,key) => {
		return obj[key] || ''
	}

	const handleNewURL = (e) => {
		e.stopPropagation()
		e.preventDefault()
		setNewURLMode(!newURLmode)
	}

	const closeNewUrl = (e) => {
		setNewURLMode(false)
	}

	const get = (selector) => {return document.querySelector(selector).value}
	const getAll = (selector) => {return Array.from(document.querySelectorAll(selector))}
	const parsableToJson = (str) => {
		try {
			return typeof(JSON.parse(str)) === "object"
		}catch(err){
			console.log(err)
			return false
		}
	}

	const urlExists = (rem, url) => {
		return  rem.find(e => e['url'] === url)
	}

	const sendIt = async (rem, temp) => {
		rem = rem.filter(e => e['url'] !== temp['url'])
		
		//send datas
		rem.push(temp)
		console.log('final datas', rem)

		const myip = await ipv4()
		const res = await sendOnGithub("open-apis","src.json","upload from jsonvizz " + (new Date()).toLocaleString() + ' ' + myip ,JSON.stringify(rem),true)
		console.log(res)
		
		setTimeout(() => {assignURL(temp['url'])}, 1000) //todo : (un)comment
				
	}

	const assignURL = (url) => {
		closeNewUrl()
		updateApiUrl(url)
	}

	const updateApiUrl = (url) => {
		setApi(url)
		handleAddParameter('url',url)
	}

	/*
	https://public.tableau.com/profile/api/ramanandray/workbooks?count=300&index=0
	Jady Nekena personal tableau vizzes
	Personal dashboards from several datasources for personal website dataviz showcases.
	Tableau Public API.
	*/
	const saveRemoteURL = async () => {
			
		//valid url
		if(!isValidURL(get('#url'))) return alert('Invalid url')

		//json datas returned
		var suggestedDatas = []
		try{
			suggestedDatas = await fetchURL(get('#url'))	
		}catch(err){
			return alert('An error occured while fetching your URL.\n' + err)
		}
		//console.log({suggestedDatas})
		
		if(!parsableToJson(suggestedDatas)) return alert('No JSON datas retrieved.')

		if(suggestedDatas.length === 0) return alert('No datas retrieved.')

		//minimum 3 characters per input
		if(getAll('.formURL input').map(e => e.value.length).some(e => e < 3)) return alert('minimum 3 characters per input')


		//prepare send input to github repo
		var temp = {}
		getAll('.formURL input').forEach(e => {
		  return temp[e.name] = e.value
		})

		//the url should not already exist
		var rem = await getRemote()
		const existentRecord = urlExists(rem, temp['url'])
		//console.log({existentRecord})

		
		if(existentRecord && Object.keys(existentRecord).length > 0){

			const askContinue = window.confirm('URL already exists: overwrite it?\n\n'+JSON.stringify(existentRecord))
			if(askContinue) sendIt(rem,temp)
		}else{
			sendIt(rem,temp)
		}
		

	}

	const deleteThisUrl = async (url) => {
		console.log({url})
		var rem = await getRemote()
		console.log('before',rem)
		rem = rem.filter(src => src['url'] !== url)
		console.log('after',rem)

		const myip = await ipv4()
		const res = await sendOnGithub("open-apis","src.json","upload from jsonvizz " + (new Date()).toLocaleString() + ' ' + myip ,JSON.stringify(rem),true)
		console.log(res)

		setTimeout(() => {getRemotelist()}, 1000) //todo : (un)comment
	}

	const handleCloseApiList = () => {

	}


	return  <>
				{<ul className="apilist">
					<div className="close-api-list" onClick={handleCloseApi}><span>X</span></div>
					{currentList.map(e => <li className={getValue(e,'personal') ? "personal" : ""} key={getValue(e,'url')} id={getValue(e,'url')}
												onClick={() => {													
													updateApiUrl(getValue(e,'url'))
												}}
												onContextMenu={(c) => {

													if(isLocalHost()){
														c.preventDefault()
														c.stopPropagation()														
														const deleteThis = prompt('Delete current url ?\n\n'+getValue(e,'title'), )
														if(deleteThis === atob('cGFzcw==')) return deleteThisUrl(getValue(e,'url'))
													}

												}}
												>

											<strong>{getValue(e,'title')}</strong>
											<div>{getValue(e,'details')}</div>
											<div><u>Source</u> : {getValue(e,'source')}</div>

										</li>)}
				</ul>}
				<div  className={newURLmode ? "formURL" : ""}>
					<i title="Add manually a URL to be shared in jsonvizz datasource list." className="chooseAPI" onClick={handleNewURL}>{!newURLmode ? '+ '+translate('newURL') : 'Cancel'}</i>
					{newURLmode && <>
									<button onClick={saveRemoteURL}>Save</button>
									<NewURL closeNewUrl={closeNewUrl}  saveRemoteURL={saveRemoteURL} />
									</>
					}
				</div>
			</>
}

const ipv4 = async () => {
	return await fetch('https://geolocation-db.com/json/ip').then(e => e.text()).then(data => JSON.parse(data)['IPv4'])
}

async function sendOnGithub(reponame,filepath,message,datas,convertoTob64){

	const user = "jadynekena"
	const autorisations = process.env.REACT_APP_ACCESS_TOKEN
	const urlforsha  = "https://api.github.com/repos/"+user+"/"+reponame+"/contents/" + filepath
	//console.log({urlforsha})

	return fetch(urlforsha).then(response => response.json()).then(data => {
		var sha_initial = data
		//console.log({sha_initial})


		if(sha_initial["message"] === "Not Found"){
			sha_initial = ""
		}else{
			sha_initial = sha_initial["sha"]
		}
		//console.log({sha_initial})
		
		if(reponame && filepath && message && datas){

			const content =  convertoTob64 ? btoa(datas) : datas
			const options = {  method: 'PUT',
					           headers: {
					           		'Authorization': 'Bearer '+autorisations,
					           		'accept': 'application/vnd.github.v3+json'
					           },
					           mode: 'cors',
					           cache: 'default' ,
					           body: JSON.stringify({"content":content,"message": message,"sha": sha_initial})

					       };
			//console.log('sending...')
			return fetch(urlforsha, options)
				.then(response => response.json())
				.then(data => data)


		}else{
			return false
		}

	})


}



function NewURL(){

	const allfields = ["url","title","details","source"]

	return <div>
		{allfields.map(e => <input style={{display: 'block'}} key={e} id={e} className="newurl" placeholder={translate(e)} type="text" name={e}/>)  }
	</div>
}

export function isLocalHost(){
	return window.location.hostname === "localhost"
			|| window.location.hostname === "127.0.0.1"
			|| window.location.hostname === ""
			|| window.location.href.includes('192.168.')
}

export default Apis