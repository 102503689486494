import {Translations} from './Translations'

function Language({ defaultValue, setLang, translate, clickedJzonviz, setClicked }){

	const first_data_key = Object.keys(Translations)[0]
	const first_translation = Translations[first_data_key]
	const possibleLang = Object.keys(first_translation)


	const updateClickedJsonvizKeys = (newLang) => {	

		//no json viz -> do nothing
		if (!clickedJzonviz) return false

		//console.log('current value of clicked jsonviz',clickedJzonviz)
		var temp = {}

		//translate keys if needed
		Object.keys(clickedJzonviz).forEach(chosenfield => {
			//console.log(('\n\n\n'))
			//console.log({chosenfield})
			//console.log({[chosenfield]: clickedJzonviz[chosenfield] })
			

			//find right translations from label 6
			const previousTranslations = Translations[6][defaultValue]
			//console.log({previousTranslations})

			const indexPreviousTranslation = previousTranslations.indexOf(chosenfield) 
			//console.log({indexPreviousTranslation})


			const newkey = Translations[6][newLang][indexPreviousTranslation]
			temp[newkey] = clickedJzonviz[chosenfield];              

			//console.log('temp',temp)
			setClicked(temp)
		})

	}

	return <div >
			<select className="lang-wrap" onChange={function(e){

						updateClickedJsonvizKeys(e.target.value)
						setLang(e.target.value)
						document.querySelector('html').setAttribute('lang', e.target.value.toLowerCase())

					}}
					defaultValue={defaultValue} >
			{possibleLang.map(e => <option key={e}>{e}</option>)}
			</select>
			</div>
}

export default Language