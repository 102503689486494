import { useState, useEffect } from 'react'
import Filterpannel from "./Filterpannel"
import Tooltip from "./Tooltip"
import Charttype, { listcharttype } from './Charttype'
import Chart from "./Chart"
import '../styles/Dataviz.css'
import Snackbar, { showAlert } from './Snackbar'
import Moremenu from './Moremenu'

function Dataviz({ 	savedDatas, updateSavedDatas, wholeDatasMode,
					lang, translate,
					datas,
					currentURL,
					filtername, setFilter,
					filterType,
					filterValue, setFilterValue,
					clickedJzonviz, setClicked,
					reinit_view,
					aggregator, setAggregator,
					setLoading,
					handleAddParameter,
					searchParams,
					handleRemoveParameter,
					remoteParams, location
				 }){
	//console.log('dataviz',lang)
	const initial_localdatas = savedDatas ? savedDatas
								: datas ? datas
								: []

	const [currentSortByField, setSortByField] = useState(null) 

 	const [localDatas, setlocalDatas] = useState(initial_localdatas) // ONLY FOR DATAVIZ PURPOSE
	const localvalues = getUniqueDatasOf(translate,datas,filtername,filterType) // ALWAYS THE SAME NO MATTER WHAT FILTER IS APPLIED
	
	const [vizDatas, setVizDatas]  = useState([])

	

	const [selectedChart, setSelectedChart]  = useState('')
	const [downloading, setDownloading] = useState(false)

	const apply_filter = (datas) => {

		//setLoading(true)
		var finalLocalDatas = []

		//update LOCAL datas
		if(filterValue === ""){
			 finalLocalDatas = datas		
		}else{
			const currentvalue = filterType === numbers_str(translate) && !isNaN(Number(filterValue)) ?  Number(filterValue) :  filterValue 
			finalLocalDatas = datas.filter((local) => {
				if(local[filtername] !== null && local[filtername] !== undefined) return local[filtername].toString() === currentvalue.toString()
				return local[filtername] === currentvalue
			})
			
		}

		//always add rownumber as new field (todo refactor in app)
		//finalLocalDatas.map((e,i) => e[translate('rownumber')] = i+1 )

		setlocalDatas(finalLocalDatas)
		//setLoading(false)
		return finalLocalDatas
	}

	const handlewholeDatas = () => {
		if(wholeDatasMode){
			if(['table','json'].indexOf(selectedChart) < 0 ) setSelectedChart('table')
			return setVizDatas(localDatas)
		}
	}

	//everytime the url updates => handle changes
	useEffect(() => {

		//get chart type from URL OR from remote parameters OR just use table by default
		setSelectedChart(searchParams.get('charttype') || remoteParams['charttype'] || listcharttype[0]) 

		//get sorting field and its direction (asc / desc)
		const currentDimensionField = clickedJzonviz[translate(6)[1]]  //by default, we use the DIMENSION to sort by, ASC
		setSortByField(searchParams.get('order') || remoteParams['order'] || (currentDimensionField ? currentDimensionField + '^asc' : null)  )


  	// eslint-disable-next-line
	}, [location])

	
	//rerender if any global variable is updated
	//when FILTER value is to updated
	//or when WHOLE datas kept
	useEffect(() => {

		//console.log('new filter', filterValue)
		apply_filter(datas)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datas, filterValue, currentURL, aggregator])


	//when jsonfield is CLICKED or DATAS are changed (by filters) or AGGREGATOR is changed (by select) or ALL DATAS clicked
	useEffect(() => {

		//do nothing but apply filter
		if(wholeDatasMode){
			//console.log({filterValue})
			handlewholeDatas()
			//setVizDatas(localDatas)	

		}else{

			//setLoading(true)
			const onlyOneField_clicked = onlyOneField(clickedJzonviz)

			//we MUST have 2 fields
			if (withDimensionAndMeasure(translate, clickedJzonviz) || onlyOneField_clicked){
				var dimensionKey = ""
				var measureKey = ""
				var finalViz = []
				
				if(onlyOneField_clicked){//dimension and measures are the same
					dimensionKey = Object.keys(clickedJzonviz)[0]       

					//console.log({localDatas})
					finalViz = countOccurences(translate, localDatas,clickedJzonviz[dimensionKey])

				}else{
					dimensionKey = clickedJzonviz[dimension_str(translate)]
					measureKey = clickedJzonviz[numbers_str(translate)]

					//map local datas to 2 fields => store it in vizDatas
					finalViz = localDatas.map(function(e){
						var tmp = {}
						tmp[dimensionKey] = e[dimensionKey]
						tmp[measureKey] =isNaN(Number(e[measureKey])) ? (changeDimToNumber(e[measureKey]) || null) : Number(e[measureKey])
						return tmp
					})


					//APPLY AGREGATOR
					if(aggregator){					
						finalViz  = apply_aggregator(translate, aggregator, clickedJzonviz, finalViz)
					}

				}

				
				if(currentSortByField){			
					const field = currentSortByField.split('^')[0]
					const order = currentSortByField.split('^')[1]
					/*
					console.log({currentSortByField})
					console.log('to sort with',field, order)
					*/
					finalViz = finalViz.sort(function(a,b){
					    return order === 'asc' ? ( a[field] - b[field] ) : ( b[field] - a[field] )   ;
					})
				}




				setVizDatas(finalViz)
				//console.log({finalViz})
				//console.log({clickedJzonviz})


			}

			//setLoading(false)




		}
		




	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[clickedJzonviz, datas, localDatas, filtername, aggregator, currentSortByField, wholeDatasMode])


	const jsonvizzChosen = (onlyOneField(clickedJzonviz) || withDimensionAndMeasure(translate, clickedJzonviz)) || wholeDatasMode
	const datasLoaded = vizDatas && vizDatas.length > 0 && jsonvizzChosen 


	return <div className="dataviz-wrap" >
				<Snackbar/>

				<Filterpannel 	lang={lang} translate={translate}
								filterType={filterType} setFilterValue={setFilterValue} filtername={filtername}
								values={localvalues} setFilter={setFilter} filterValue={filterValue}
								handleAddParameter={handleAddParameter}
								handleRemoveParameter={handleRemoveParameter}
								/>

				<div className="dataviz" >

					<div className="viz-topmenu">
						<div className="titleVizWrap">
								
				            {jsonvizzChosen &&
				            	<span className="viz-btn-right-wrap">
									<Charttype wholeDatasMode={wholeDatasMode} translate={translate} selectedChart={selectedChart} setSelectedChart={setSelectedChart}
												handleAddParameter={handleAddParameter}
											/>
					            </span>
					        }
					        
							<Tooltip wholeDatasMode={wholeDatasMode}
									 selectedChart={selectedChart}
									 currentSortByField={currentSortByField} setSortByField={setSortByField} 
									 translate={translate} onlyOneField={onlyOneField(clickedJzonviz)} clickedJzonviz={clickedJzonviz} setClicked={setClicked}
									 aggregator={aggregator} setAggregator={setAggregator}
									 setFilterValue={setFilterValue}
									 filtername={filtername}
									 filterValue={filterValue}
									 handleRemoveParameter={handleRemoveParameter}
									 handleAddParameter={handleAddParameter}
							/>
				        </div>

				    	{/*MORE BUTTONS MENU HERE*/}
				    	{datasLoaded && <Moremenu vizDatas={vizDatas} updateSavedDatas={updateSavedDatas} translate={translate} reinit_view={reinit_view} selectedChart={selectedChart} showAlert={showAlert} setLoading={setLoading} downloading={downloading} setDownloading={setDownloading}
				    				currentURL={currentURL} remoteParams={remoteParams} />
				    	}

						
					</div>

					{datasLoaded &&
						<div className="vizwrap" >		
							<Chart  source={currentURL}
									downloading={downloading}
								    selectedChart={selectedChart}
									clickedJzonviz={clickedJzonviz}
									datas={vizDatas} setVizDatas={setVizDatas} /*useful for ordering*/
									xAxis={ currentXaxis(translate, clickedJzonviz) }
								    yAxis={ currentYaxis(translate, clickedJzonviz) }
							/>		
						</div>
					}



				</div>
			</div>

}


function currentXaxis(translate,clickedJzonviz){
	return withDimensionAndMeasure(translate, clickedJzonviz) ? clickedJzonviz[dimension_str(translate)] : chosenFieldForHistogram(clickedJzonviz)
}

function currentYaxis(translate,clickedJzonviz){
	return withDimensionAndMeasure(translate, clickedJzonviz) ? clickedJzonviz[numbers_str(translate)] : occurenceName(translate)
}

function numbers_str(translate){ 
	return translate(6)[4]
}

function dimension_str(translate){
	return translate(6)[1]
}

function apply_aggregator(translate, aggregator, clickedJzonviz, datas){
	var res = datas
	var fieldname = currentXaxis(translate, clickedJzonviz)
	var displayedfield = currentYaxis(translate, clickedJzonviz)

	/*
	console.log({fieldname})
	console.log({displayedfield})
	*/



	var currentXvalues = getUniqueDatasOf(translate, datas,fieldname)
	const tmp = currentXvalues.map(function(e){

		const calc = func_to_aggregate(aggregator,datas,fieldname,displayedfield,e) 
		return {
			[fieldname]: e,
			[displayedfield]: calc
		}

	})

	//console.log({tmp})
	res = tmp

	return res

}

function func_to_aggregate(aggregator,datas,fieldname,displayedfield,e){
	//console.log('\n\n\n')
	//console.log({datas})
	var filtered_datas = datas.filter(data => data[fieldname] === e)
	var res = datas // default : all without aggregate


	if(aggregator === 'count'){
		res = filtered_datas.length

	}else if(aggregator === 'sum'){
		res = filtered_datas.map(data => data[displayedfield]).reduce((a,b) => a+b)
	
	}else if(aggregator === 'average'){
		res = filtered_datas.map(data => data[displayedfield]).reduce(function (avg, value, _, { length }) {
	        return avg + value / length;
	    }, 0);
		if(!isNaN(Number(res))) res =  res.toFixed(2)
	
	}else if(aggregator === 'max'){
		res = Math.max(...filtered_datas.map(data => data[displayedfield]))
	
	}else if(aggregator === 'min'){
		res = Math.min(...filtered_datas.map(data => data[displayedfield]))
	}


	return res
}



function chosenFieldForHistogram(clickedJzonviz){
	return clickedJzonviz[Object.keys(clickedJzonviz)[0]]
}

function onlyOneField(clickedJzonviz){
	return Object.keys(clickedJzonviz).length === 1
}



function withDimensionAndMeasure(translate, clickedJzonviz){
	return Object.keys(clickedJzonviz).length >= 2 && clickedJzonviz[dimension_str(translate)] && clickedJzonviz
}

function getUniqueDatasOf(translate,localDatas,fieldname,filterType){
	
	var res = [...new Set(localDatas.map(item => item[fieldname]).filter(e => e).sort()  )   ] //discard null values (TODO)

	const isactuallyNumber = isInnerlyNumber(translate,filterType,res[0])
	//console.log({isactuallyNumber})

	//sort by dimension here
	/*
	console.log('\n\n\n\n')
	console.log({filterType})
	console.log({isactuallyNumber})
	console.log('before',res)
	*/
	if(  filterType === numbers_str(translate)  || isactuallyNumber ){
		res = sortproperly(translate,filterType,res)
	} 
	//console.log('after',res)

	return res
}


function sortproperly(translate,filterType,res){
	return res.sort(function(a, b){
		const a_transf = transformToNumber(translate,filterType,a)
		const b_transf = transformToNumber(translate,filterType,b)
		return a_transf-b_transf
	})
}


export function isInnerlyNumber(translate,filterType,str){
	//console.log('CHECKING IF INNERLY NUMBER',str)
	if(filterType === numbers_str(translate)) return true

	const isnotnull = str ? true : false
	var isnumber = false

	if(isnotnull){			
		const transformedval = transformToNumber(translate, filterType,str)
		if(transformedval.length === 0) return false
			
		//console.log({transformedval})
		const isnumber = !isNaN(Number(transformedval))
		//console.log({isnumber})
		return isnumber
	}


	return isnumber

}

function changeDimToNumber(str){
	return right_decimal_delimiter(no_currencies(no_space(no_letters((str || "").toString())))) 
}

function transformToNumber(translate, filterType,str){
	return filterType && filterType && !isNaN(Number(str)) && filterType === numbers_str(translate) ? Number(str) : changeDimToNumber(str)
}



function no_letters(str){
	return str.replace(/[^\d.,-]/g, '').split(' ')[0].split(' ')[0]; //get only first word
}

function no_space(str){
	return str.replaceAll(' ','').replaceAll(String.fromCharCode(160),'').replace(/\s/g,'');
}

function right_decimal_delimiter(str){
	return str.replaceAll(',','.')
}

function no_currencies(str){
	return str.replaceAll('€','').replaceAll('$','').replaceAll('£','')
}








function occurenceName(translate){
	return translate(25)
}

function countOccurences(translate, a,fieldname){
	/*
	console.log('local_datas from countOccurences:',a)
	console.log('fieldname from countOccurences:',fieldname)
	*/

	const dimensionValues = getUniqueDatasOf(translate, a,fieldname)
	//console.log({dimensionValues})
	return dimensionValues.map(function(e){
		return {
			[fieldname]: e,
			[occurenceName(translate)]: a.filter(data => data[fieldname] === e).length
		}

	})
}





export default Dataviz