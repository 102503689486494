import { translate } from './Translations'
function Pagination({datalength, maxRows, currentPage, handlePagination, min, max}){
	const finalPage = Math.ceil(datalength / maxRows)
	const allpages = Array.from({length: finalPage}, (_, i) => i + 1)		
	return <div className="pagination">
				<h3>{translate(5)}s {min} {translate('to')} {Math.min(max,datalength)} (Total: {datalength})</h3>
				{allpages.map((pageNumber,index) => <OnePage key={pageNumber} pageNumber={pageNumber} currentPage={currentPage} handlePagination={handlePagination}/>)}

			</div>
	
}

function OnePage({pageNumber, currentPage, handlePagination}){
	const additional = pageNumber === currentPage ? ' activepage' : ""
	return <a href={"#page="+pageNumber} className={"page" + additional} onClick={() => handlePagination(pageNumber)}>[ {pageNumber} ]</a>


}


export default Pagination