import '../styles/App.css'
import '../styles/Navbar.css'
import Loading from './Loading'
import Datasource/*, { mainTransform, addRowNumber }*/ from './Datasource'
import Header from './Header'
import Text  from './Text'
import Wrap from './Wrap'
import Sidebar from './Sidebar'
import Viz from './Viz'
import Groupfield from './Groupfield'
import Dataviz from './Dataviz'
import { useOutletContext, useSearchParams, useLocation } from 'react-router-dom'

//import { useRef } from 'react'
import { useState, useEffect } from 'react'


function App({remoteParams, setRemoteParams}) {

  if(!remoteParams) remoteParams = {}
  //console.log({remoteParams})

  // everything related to the router dom
  const { lang, translate } =  useOutletContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()


  const navElements = ['Import', 'Dataviz']
  const [currentNav, setCurrentNav] = useState('')

  //const bottomRef = useRef(null);

  const [isValid, setValid] = useState(false)
  const [savedDatas, updateSavedDatas] = useState([]) //todo
  const [wholeDatasMode, setWholeDatasMode] = useState(false)

  const [datas, updateDatas] = useState([])
  
  const [currentlyLoading, setLoadingLocal] = useState(false)

  const [currentURL, setURLsource] = useState('')
  
  const defaultPostValue = JSON.stringify({   "GSHEET_URL":"https://docs.google.com/spreadsheets/d/XXXX/edit",
                                "SHEET_NAME":"XXXX",
                                "DATAS_FIRST_ROW":1,
                                "CONFIG": {
                                  "select": ["*"],
                                  "whereFilter": {
                                      "field1" : {"operation": "!==", "value":""},
                                      "field2" : {"operation": "!==", "value":""},
                                    },
                                  "orderBy" : {"field3":"asc"}
                                }
                            })
  const [postDatas, setPostDatas] = useState(defaultPostValue)

  const [showPostDatasMode, setShowPostDatas] = useState(false) 

  const [filtername, setFilter] = useState('') //filtername

  const [filterValue, setFilterValue] = useState('') //filter value ONLY FOR DATAVIZ PURPOSE

  //todo
  const current_filter_type = () => {
    return ''
  }
  const [filterType, setFilterType] = useState(current_filter_type())//type of filter
  const [viewFields, setViewFields] = useState(false)//if we view fields from sidebar or not
  const [clickedJzonviz, setClicked] = useState({})
  const [aggregator, setAggregator] = useState('count')
  

  //on updates of location, reapply states  
  useEffect(() => {

    
    console.log('\n\n')
    console.log('Location changed : updating state of the app',location)
    
    
    const currentFilterFromURL = () => {

      const filterURLparam = Array.from(searchParams).flat().find(e => e.includes('filter['))
      const filtername = filterURLparam ? extractValueInSquareBrackets(filterURLparam) : ""
      const filterValue = filterURLparam ? searchParams.get('filter['+filtername+']') : ""

      return {filtername, filterValue}

    }
    
    const currentClickedJsonvizz = () => {
      let res = {}

      const dim_translation = translate(6)[1]
      const num_translation = translate(6)[4]
      const default_dim = searchParams.get('dim') ||  remoteParams['dim'] || ''
      const default_num = searchParams.get('num') ||  remoteParams['num'] || ''

      if(default_dim) res[dim_translation] = default_dim
      if(default_num) res[num_translation] = default_num

      //console.log({res})

      return res
    }
    
    setWholeDatasMode( searchParams.get('show_all') || remoteParams['show_all'] || false )

    //in the url parameter OR saved url remotely 
    setURLsource(searchParams.get('url') || remoteParams['url'] || '')

    //filter is synced with current URL
    setFilter(currentFilterFromURL()['filtername'])
    setFilterValue(currentFilterFromURL()['filterValue'])

    setClicked( currentClickedJsonvizz() ) // dim and num
    setAggregator( searchParams.get('agg') ||  remoteParams['agg'] || 'count' )


  // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    //console.log('url source changed to ', currentURL)
    
    //no url source => no datas + go back to import
    if(!currentURL) {
      setCurrentNav('nav_Import')
      updateDatas([])
    } 

  }, [currentURL])



  const extractValueInSquareBrackets = (variable) => {
    const regex = /filter\[(.*?)\]/;
    const match = regex.exec(variable);
    if (match && match.length > 1) {
        return match[1]; // Extracted value inside square brackets
    }
    return null; // Return null if no match found
  }


  const setLoading = (load) => {
    setLoadingLocal(load)
    /*
    if(document.getElementById('dataviz')){
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    } 
    */
  }

  const reloadDatas = async () => {
    console.log('reload datas => todo')
  }
  

  //add 1 URL parameter
  const handleAddParameter = (parameterName, parameterValue, forcing) => {
    if ((parameterValue && parameterValue.trim() !== '') || forcing) {
      searchParams.set(parameterName, parameterValue);
      setSearchParams(searchParams);
    } else {
      handleRemoveParameter(parameterName)
    }
  }

  //remove 1 URL parameter
  const handleRemoveParameter = (parameterName) => {
    if (searchParams.has(parameterName)) {
      searchParams.delete(parameterName);
      setSearchParams(searchParams);
      
    }
  };

  


  const hideSideBarOnMobile = (yes) => {
      if(document.querySelector('#toggleSidebar')) document.querySelector('#toggleSidebar').checked = yes ? false : true //hide side bar

  }


  const seeAll = () => {

    setLoadingLocal(true)
    reinit_view()
    
    //set local datas to ALL
    updateSavedDatas(datas)
    setWholeDatasMode(true)

    setLoadingLocal(false)
    
    //add type param in current URL
    handleAddParameter('show_all','true')

  }
  
  const reinit_view = () => {
    
      setClicked({})
      setFilter('') 
      setFilterType('')
      setFilterValue('')

      setViewFields(false)
      hideSideBarOnMobile(true)
      setWholeDatasMode(false)

      //remove all parameters EXCEPT url
      remove_all_params_except_url()

    }

  const remove_all_params_except_url = () => {
      handleRemoveParameter('show_all')

      //no chartype given
      handleRemoveParameter('charttype')
      
      //no filter
      handleRemoveParameter('filter['+filtername+']')

      //no current chosen elements
      handleRemoveParameter('dim')
      handleRemoveParameter('num')

      //no agregation
      handleRemoveParameter('agg')

      //no sorting
      handleRemoveParameter('order')

      //no page
      handleRemoveParameter('page')

      //no nav
      handleRemoveParameter('nav')
  }


  const handleNavClick = (e) => {

    //user wants to do dataviz
    if(e.target.id === 'nav_Dataviz'){

      console.log({datas})
      console.log({currentURL})
      
      //no datas yet AND no provided URL
      if(datas.length===0 && !currentURL){

        alert(translate('nodatayet'))
        handleRemoveParameter('nav')
        setCurrentNav('nav_Import')
        console.log('nav is nav_Import cause NO DATAS')
        
      //there are already datas OR there is a provided URL
      } else{

        //there is a provided URL
        if(datas.length === 0){
          handleRemoveParameter('nav')
          setCurrentNav('nav_Import')
          document.getElementById('start').click()
          console.log('nav is nav_Import cause NO DATAS, but it is loading...')

        //there are already datas
        } else {
          handleAddParameter('nav','viz')
          setCurrentNav('nav_Dataviz')
          console.log('nav is now nav_Dataviz cause we clicked on it')
        } 

      }



    //user wants to import 
    }else if(e.target.id === 'nav_Import'){
      handleRemoveParameter('nav')
      setCurrentNav('nav_Import')  
      console.log('nav is now nav_Import cause we clicked on it')
    }




  }




  return (
    <div className="main-app">
        <nav>
          <ol>
            {navElements.map(el => <li className={'nav_'+el === currentNav ? "activenav" : "" } key={'nav_'+el} id={'nav_'+el} onClick={handleNavClick}>{el}</li>)}
          </ol>
        </nav>

      {(/*datas.length === 0 ||*/ currentNav === 'nav_Import' ) && <section className="onepage centered-in-page">
              <div className="demo-content">
                <Text withURL={(datas.length > 0)} translate={translate}/>
              </div>
      
              {currentlyLoading  && <Loading/>}
              <Header withDatas={(datas.length > 0).toString()}>
                <Datasource lang={lang}
                            translate={translate}
                            defaultPostValue={defaultPostValue}
                            postDatas={postDatas}
                            setPostDatas={setPostDatas}
                            showPostDatasMode={showPostDatasMode}
                            setShowPostDatas={setShowPostDatas}
                            defaultURL={searchParams.get('url') || remoteParams['url'] || ''} encode_str={encode_str}
                            isValid={isValid} setValid={setValid}
                            currentURL={currentURL} setURLsource={setURLsource}
                            datas={datas} updateDatas={updateDatas}
                            currentlyLoading={currentlyLoading} setLoading={setLoading}
                            reinit_view={reinit_view}
                            handleAddParameter={handleAddParameter}
                            handleRemoveParameter={handleRemoveParameter}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            setCurrentNav={setCurrentNav}
                            />
              </Header>
            </section>}


      {/*datas.length > 0 &&*/ currentNav === 'nav_Dataviz' &&
          <Wrap >
          <Sidebar setViewFields={setViewFields} viewFields={viewFields}>
            <div className="sidebtns">
              <button className="reload" title={translate('reload')} onClick={reloadDatas}></button>
              <button onClick={seeAll}>{translate('all')}</button>
            </div>

            <h2>{datas.length} {translate(5)}{datas.length > 1 && 's'}</h2>


            {
              translate(6).map((e,i) => <Groupfield setWholeDatasMode={setWholeDatasMode}
                                            translate={translate} lang={lang}
                                            key={e} setViewFields={setViewFields} viewFields={viewFields}
                                            setFilter={setFilter} setFilterType={setFilterType}
                                            title={e} groupid={'group'+i} datas={datas} clickedJzonviz={clickedJzonviz} setClicked={setClicked}
                                            aggregator={aggregator} setAggregator={setAggregator}
                                            setLoading={setLoading}
                                            setFilterValue={setFilterValue}
                                            handleRemoveParameter={handleRemoveParameter}
                                            filtername={filtername}
                                            handleAddParameter={handleAddParameter}
                                            searchParams={searchParams}
                                            />)
            }
          </Sidebar>

          {viewFields===false &&
            <Viz >
              <Dataviz savedDatas={savedDatas} updateSavedDatas={updateSavedDatas} wholeDatasMode={wholeDatasMode}
                      lang={lang} translate={translate}
                      filterValue={filterValue} setFilterValue={setFilterValue } 
                      clickedJzonviz={clickedJzonviz} setClicked={setClicked}
                      filterType={filterType} filtername={filtername} setFilter={setFilter}
                      datas={datas}
                      currentURL={currentURL}
                      reinit_view={reinit_view}
                      aggregator={aggregator} setAggregator={setAggregator}
                      setLoading={setLoading}
                      handleAddParameter={handleAddParameter}
                      searchParams={searchParams}
                      handleRemoveParameter={handleRemoveParameter}
                      remoteParams={remoteParams}
                      location={location}
                      />
            </Viz>}
        </Wrap>
      }

      {/*<div className={datas.length === 0 ? "bottomRef" : "smallBottomRef"} ref={bottomRef}></div>*/}
      <div id="topping"></div>
    </div>
  );
}

function encode_str(plain,localencoder){
  if(!plain) return ''
  var res = ''

  for (var i = 0 ; i < plain.length ; i++) {
    const s = plain.charAt(i)
    const curr_char_code = s.charCodeAt()
    const next_char_code = curr_char_code + (localencoder ? localencoder : 1)
    const replacer = String.fromCharCode(next_char_code)
    res += replacer
  }
  return res;
}

/*
function decode_str(encoded){
  return encode_str(encoded,(-1)*1)
}
*/


export default App;
