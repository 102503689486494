import '../styles/Groupfield.css'
import Jsonfield from './Jsonfield'
import { isInnerlyNumber } from './Dataviz'

function Groupfield({	setWholeDatasMode,
						translate, lang,
						datas, title, groupid,
						setFilter, setFilterType,
						viewFields, setViewFields,
						clickedJzonviz, setClicked,
						aggregator, setAggregator,
						setLoading, setFilterValue,
						handleRemoveParameter, filtername,
						handleAddParameter, searchParams
					}){

	//console.log('groupfield', lang)

	const isVisible = title!=='Calculs' && title!=='Histogrammes'
	const allFields = getFields({datas,title,groupid})




	function isNumber(str){

		const dimension_str = () => {
			return translate(6)[1]
		}

		//(NOT dimension or DIM+INNERNUMBER)
		//and NOT date and NOT image and NOT link
		return (!isDimension(str||"")
				|| (isDimension(str||"") && isInnerlyNumber(translate, dimension_str(), str||""))
				)

				&& !isDate(str||"") && !isImage(str||"") && !isLink(str||"")
	}


	function isDimension(str){
		//boolean OR
		//is a number OR
		//(not a number OR no value) AND not date AND not link AND not image
		return isBoolean(str||"")
				|| !isNaN(Number(str||"")) //NEW
				|| (    (  isNaN(Number(str||"")) || (str||"").toString().length === 0) && !isDate(str||"") && !isLink(str||"") && !isImage(str||""))
	}

	function isBoolean(str){
		return typeof str == "boolean"
	}


	function getFields({datas, title, groupid}){
		//console.log('-------------------'+title.toUpperCase()+'------------------')

		var listFields = datas.length > 0 ? Object.keys(datas[0]) : []
		var finalFields = []

		//for each key
		for (const oneField of listFields){

			//get first NON null data as oneData
			const oneData = datas.find(content => content[oneField] !== null )

			if(oneData){

				const fieldValue =  oneData[oneField] || ""

				//then identify the field type with getFunctionIdentifier
				const functionIdentifier = getFunctionIdentifier({groupid,oneData})

				//if the current field HAS the criteria -> keep it
				//if the current field DO NOT HAVE the criteria -> SKIP it
				const to_keep = functionIdentifier(fieldValue)
				if(to_keep) finalFields.push(oneField)

			
			}else{
				console.warn('Detected useless field: ',oneField)
			}
		}

		//TODO refactor in app
		//if group of Numbers or Dimensions => always add calculated row number (only once)
		if((groupid === 'group4' || groupid === 'group1' ) && rownumberDoesNotExist(finalFields)){
			//console.log('	groupfield getFields group4',lang)
			const newField = translate('rownumber')
			//console.log({newField})
			//finalFields.push(newField)
		}

		finalFields = finalFields.map(function(e){
			return {
				name: e,
				value: e,
				id: e,
				group: title,
				type: title,
				func: null
			}
		})

		finalFields = finalFields.sort(function(a,b){
			//sort by ID
	    	return b.id < a.id ?  1 // if b should come earlier, push a to end
	         : b.id > a.id ? -1 // if b should come later, push a to begin
	         : 0;                   // a and b are equal
		})
		
		//console.log({finalFields})
		return finalFields
	}

	function rownumberDoesNotExist(finalFields){
		return finalFields.indexOf(translate('rownumber')) < 0
	}

	function getFunctionIdentifier({groupid,oneData}){
		groupid = groupid.replace('group','')

		return groupid === '0' ? isDate //dates
			   : groupid === '1' ? isDimension //dimensions
			   : groupid === '2' ? isLink //links
			   : groupid === '3' ? isImage //images
			   : groupid === '4' ? isNumber //numbers
			   : alwaysTrue
	}

	function alwaysTrue(anything){
		return true
	}

	function generictype(groupid,translate){ 
		// numbers are group 4
		// else, dimensions => group 1
		return (groupid === 'group4') ? translate(6)[4] : translate(6)[1]
	}

	function blankspace(){
		return '‎'
	}

	
	return isVisible && allFields.length > 0 &&

		<div id={groupid} className="groupfield">

			<h2>{title}</h2>
			{datas && allFields.map(function(e){
				return <div key={e['id']}>
						<Jsonfield  setWholeDatasMode={setWholeDatasMode}
									translate={translate} lang={lang}
									clickedJzonviz={clickedJzonviz}
									setClicked={setClicked}
									setViewFields={setViewFields}
									viewFields={viewFields}
									generictype={generictype(groupid,translate)}
									setFilter={setFilter}
									setFilterType={setFilterType}
									blankspace={blankspace}
									type={e['type']}
									name={e['name']}
									id={e['id']}
									func={e['func']}
									setAggregator={setAggregator}
									setFilterValue={setFilterValue}
									handleRemoveParameter={handleRemoveParameter}
									filtername={filtername}
									handleAddParameter={handleAddParameter}
									searchParams={searchParams}
									/>
						</div>
			})}



		</div>
}

export function isDate(str) {
    return (new Date(str||"") !== "Invalid Date") && !isNaN(new Date(str||"")) && (str||"").toString().substring(0, 1) !== '-'  && ((str||"").toString().includes('-') || (str||"").toString().includes('/')) ;
}



export function isValidURL(str){
  let url;
  
  try {
    url = new URL(str||"");
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}


export function isLink(str){
	return isValidURL(str||"") && !isImage(str )
}

export function isImage(str){
	return (str||"").toString().match(/.(jpg|jpeg|png|gif)$/i) === null ? false : true
}

export default Groupfield