import { useState } from 'react'

export const Translations = {

	'nodatayet': {
		'FR': 'Aucune donnée chargée, merci de choisir une URL source.',
		'EN': 'No datas yet, please pick a URL source.'
	},

	'keyChoice': {
		'FR': '⚠️ Plusieurs clés trouvées.\n\nLaquelle est la source de données ? Si vous souhaitez tout garder, laissez ce champ vide.',
		'EN': '⚠️ Multiple keys were found.\n\nWhich one is your data source ? If you want to keep them all, leave it blank.'
	},

	'loading' : {
		'FR': "Chargement en cours...",
		'EN': "Loading..."
	},

	'jsonPOST' : {
		'FR': "Votre JSON à envoyer par la méthode POST...",
		'EN': "Your to-be-posted JSON..."
	},

	'logo' : {
		"FR": "Un visualisateur de JSON <strong>simple</strong> et <strong>rapide</strong>.",
		"EN": "A very <strong>simple</strong> and <strong>fast</strong> JSON visualizer."

	},

	'docs' : {
		"FR": "Tutoriel",
		"EN": "Documentation"
	},

	'openAPI': {
		"FR": "➝ Pas d'URL sous la main ? Choisissez parmi les API ouvertes ! ",
		"EN": "➝ No URL ? Choose among open APIs list ! "
	},

	'choiceAPI': {
		"FR": "↓ Choisissez votre source de données",
		"EN": "↓ Choose your datasets"
	},

	'0bis' : {
		"FR": "Sauvegarder l'URL",
		"EN": "Save the URL"
	},

	'src':{
		"FR": "Source des données",
		"EN": "Datasource"
	},

	'src-title': {
		"FR": "Cliquez pour ouvrir la source URL",
		"EN": "Click to open URL source"
	},

	0: {
		'FR': 'Votre URL source de JSON...',
		'EN': 'Your JSON URL source...',
	},

	1: {
		'FR': "Ce n'est pas une URL valide.",
		'EN': "Your URL is not a valid one."
	},

	2: {
		"FR": "Votre URL est vide.",
		"EN": "Please provide the URL."
	},

	3: {
		"FR": "Impossible de traiter l'URL.",
		"EN": "Impossible to fetch this URL."
	},

	'cors': {
		"FR": "Il s'agit généralement de problème CORS.",
		"EN": "This is probably a CORS issue."
	},

	'notJSON': {
		"FR": "Le contenu n'est pas un JSON.",
		"EN": "The retrieved content is not a JSON."
	},

	'newURL': {
		"FR":"Ajouter une URL",
		"EN":"Add a new URL"
	},

	'url':{
		'FR': 'Votre URL',
		'EN': 'Your URL'
	},

	'details':{
		"FR": "Description",
		"EN": "Description"
	},

	'title': {
		"FR": "Titre",
		"EN": "Title"
	},

	'source':{
		"FR": "Source",
		"EN": "Source"
	},

	'start': {
		"FR": "Commencer",
		"EN": "Get started"
	},

	5: {
		"FR": "ligne",
		"EN": "row"
	},

	'reload': {
		"FR": "Actualiser",
		"EN": "Refresh"
	},

	'charttype': {
		"FR": "Cliquez pour changer de type de graphique",
		"EN": "Click to switch chart type"
	},

	6: {
		"FR": ['Dates','Dimensions','Liens','Images','Nombres'], //NEVER CHANGE ORDERS !!!!!!!
		"EN": ['Dates','Dimensions','Links','Images','Numbers'] //NEVER CHANGE ORDERS !!!!!!!
	},

	7: {
		"FR": 'Tous',
		"EN": 'All'
	},

	"filter" : {
		"FR": "Aucun filtre appliqué",
		"EN": "No current filter",
		
	},

	"filter-mobile" : {
		"FR": "Cliquez sur ☰ pour choisir un filtre ",
		"EN": "Click on ☰ to choose a filter ",
		
	},

	10: {
		"FR": "Ce tableau recense toutes les données",
		"EN": "This table shows the whole datasets"
	},


	11: {
		"FR": "Cliquez sur un champ ",
		"EN": "Click on one of "
	},

	12: {
		"FR": " et/ou un champ ",
		"EN": " fields and/or ",
	},

	13: {
		"FR": "Quelconque",
		"EN": "Any field"
	},

	14: {
		"FR": "Supprimer le filtre",
		"EN": "Remove all filters"
	},

	'no-current-filter': {
		"FR": "Effacer le filtre",
		"EN": "Remove current filter"
	},

	'chart' : {
		"FR": "Ce graphique",
		"EN": "This chart"
	},

	'table' : {
		"FR": "Ce tableau",
		"EN": "This table"
	},

	15: {
		"FR": " indique ",
		"EN": " shows ",
	},

	16 : {
		"FR": " de ",
		"EN": " of ",
	},

	17: {
		"FR": "chaque valeur",
		"EN": "each occurence"
	},

	"count" : {
		"FR": "Nombre",
		"EN": "Number",
	},

	"sum" : {
		"FR": "Somme",
		"EN": "Sum",
	},

	"average" : {
		"FR": "Moyenne",
		"EN": "Average",
	},

	"min" : {
		"FR": "Minimum",
		"EN": "Minimum",
	},

	"max" : {
		"FR": "Maximum",
		"EN": "Maximum",
	},
	
	18: {
		"FR": "Réinitialiser le graphique",
		"EN": "Reset chart"
	},

	19: {
		"FR": "Cliquez pour ajouter «",
		"EN": "Click to add «"
	},
	

	20: {
		"FR": "» dans la visualisation",
		"EN": "» in the Data vizualisation"
	},

	'20dim': {
		"FR": " en tant que Dimension",
		"EN": " as a Dimension"
	},

	'20num': {
		"FR": " en tant que Nombre",
		"EN": " as a Number"
	},

	21: {
		"FR": "Filtrer les données avec le champ ",
		"EN" : "Filter datas with "
	},

	22: {
		"FR": "Enregistrer",
		"EN": "Save"
	},

	23: {
		"FR": "Configurer le champ numérique ",
		"EN": "Setup numeric field "
	},

	24: {
		"FR": "Calculs par défaut pour ",
		"EN": "Default calculations for "
	},

	25: {
		"FR": "Nombre d'apparitions",
		"EN": "Occurences"
	},

	26: {
		"FR": " par ",
		"EN": " per "
	},

	27: {
		"FR": "Enlever du graphique",
		"EN": "Remove from chart"
	},

	28: {
		"FR": "Changer le mode",
		"EN": "Switch light"
	},


	29: {
		"FR": "Trier par ce champ (ordre croissant)",
		"EN": "Order by this field (ascending)"
	},

	30: {
		"FR": "trié par",
		"EN": "ordered by"
	},

	31: {
		"FR": "Double-cliquez pour ouvrir le lien dans un nouvel onglet.",
		"EN": "Double click to open current link in a new tab."
	},

	32: {
		"FR": "Prévisualisez les images en changeant le type de graphique en nuage de points.",
		"EN": "Preview images by changing your chart type to scatter points."
	},

	'rownumber': {
		"FR": "ligne_numero",
		"EN": "row_number"
	},

	34: {
		"FR": "Télécharger en PNG",
		"EN": "Download as PNG"
	},

	"download" : {
		"FR": "⚠️ Le téléchargement n'est disponible qu'en mode éclairé. Voulez-vous continuer ?",
		"EN": "⚠️ Downloads are only available in light mode. Do you want to proceed anyway ?"
	},

	'copy': {
		"FR": "Copier",
		"EN": "Copy"
	},

	'copied' : {
		"FR": "✅ Copié dans le presse-papier.",
		"EN": "✅ Copied to clipboard."

	},

	'docs-header': {
		"FR": "Tutoriel (en cours de rédaction)",
		"EN": "Documentation (pending)"
	},

	'embed-header': {
		"FR": "Embed ici (à faire)",
		"EN": "Embed will work here (todo)"
	},

	404: {
		"FR": "Erreur 404 - Page non trouvée",
		"EN": "404 - Page not found",
	},

	'back': {
		"FR": "Retourner à la page d'accueil",
		"EN": "Back home"

	},

	'save': {
		"FR": "Enregistrer",
		"EN": "Save"

	},

	'saving': {
		"FR": "Enregistrement sous le numero ",
		"EN": "Saving as "
	},

	'embed': {
		"FR": "Intégrer dans votre site (todo)",
		"EN": "Embed in your website (todo)"
	},

	'tipURL': {
		"FR": "Double-cliquez l'élément pour ouvrir le lien dans un nouvel onglet.",
		"EN": "Double-click the element to open the link in a new tab."
	},

	'all': {
		"FR": "Voir toutes les données",
		"EN": "See all datas"
	},

	'to': {
		"FR":"à",
		"EN":"to"
	},

	'where': {
		"FR": "où",
		"EN": "where"
	},

	'equals to': {
		"FR": "vaut",
		"EN": "equals to"
	},
	

}

export const nav_lang = () => {
    return navigator.language.toUpperCase() === 'FR' ? 'FR' : 'EN'
}

export function TranslationsState(){

	const userLang = nav_lang()
	const [lang, setLangLocal] = useState(window.localStorage.getItem('lang') || userLang)
	const setLang = (newlang) => {
		setLangLocal(newlang)
		window.localStorage.setItem('lang',newlang)
	}

	const res =  {lang, setLang, translate}
	return res
  
}

function getTranslation(labelID, lang){
	return (Translations[labelID] ? Translations[labelID][lang] || Translations[labelID]['EN'] : null) //default in english if the translation is missing
}


export const translate = (labelID, optionalTargetLang) => {
	const lang = window.localStorage.getItem('lang') || nav_lang()
	return optionalTargetLang ? getTranslation(labelID, optionalTargetLang) : getTranslation(labelID, lang)
}