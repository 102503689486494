function Charttype({wholeDatasMode, translate, selectedChart,setSelectedChart, handleAddParameter}){
	//todo: pie and text
	const list = wholeDatasMode ? ['table','json'] : listcharttype

	const nextChart = (val) => {
		return list[(list.indexOf(val)+1)%list.length]
	}
	
	const processCharttype = (e) => {
		const current_val = e.target.value
		const next_val = nextChart(current_val)
		setSelectedChart(next_val)

		//add url param
		handleAddParameter('charttype',next_val)

		if(next_val !== 'table') handleAddParameter('page','')
	}

	const noth = () => {

	}

	return  <button title={translate('charttype')}
				className="charttype-wrap"
				value={selectedChart}
				onClick={processCharttype}>

					<ul className="listcharttype">
					{list.map(e => <div key={e}>
										<input type="radio" value={e} id={e} name="charttype"
											onChange={noth}
											chosen={(selectedChart === e).toString()} />
									  	<label htmlFor={e}>
									  		<img alt={e} className="icon" src={require('../assets/'+e+'.svg')}/>
								  		</label>
							  		</div>
							)}
					</ul>
			</button>
}


export const listcharttype = ['table','bar','line','scatter','json'] //todo : add text and pie



export default Charttype