import { randomkey, cleanIt } from './JsonToTable'
import { isValidURL, isImage } from './Groupfield'

function JsonToBody({data, column }){

	// get table row data
	const tdData =() =>{

		return data.map((data)=>{
			return(
				<tr key={randomkey()}>
				    {
				       column.map((v)=>{
				       	//console.log('	',data[v])
				           return <td key={randomkey()}>   <LinkTable content={  cleanIt(data[v]) }/>    </td>
				       })
				    }
				</tr>
			)
	 })
	}

	return <>{tdData()}</>

}

function LinkTable({content}){
	if(content!=="null"){
		return  isImage(content) ? <a href={content} target="_blank" rel="noreferrer"><img loading="lazy" className="imgtable" alt={content} src={content}/></a> 
				: isValidURL(content) ? <a href={content} target="_blank" rel="noreferrer">{content}</a> 
				: <span>{content}</span>
	}else{
		return <span></span>
	}
}

export default JsonToBody