function Aggregator({translate, defaultValue, onChange, fromSidebar,onlyOneField}){
	return <select className="aggregator" value={defaultValue} onChange= {onChange}>
				<option value="count">{fromSidebar ? translate("count") : (onlyOneField ? translate(17) : translate("count"))}</option>
				{!onlyOneField && <option value="sum">{translate("sum")}</option>}
				{!onlyOneField && <option value="average">{translate("average")}</option>}
				{!onlyOneField && <option value="min">{translate("min")}</option>}
				{!onlyOneField && <option value="max">{translate("max")}</option>}
			</select>
}

export default Aggregator