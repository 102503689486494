import ReactECharts  from 'echarts-for-react'
import { isImage, isValidURL } from './Groupfield'
import JsonToTable from './JsonToTable'
import Jsonviewer from './Jsonviewer'
import { translate } from  './Translations'
import '../styles/Chart.css'

function Chart({source, datas,setVizDatas, xAxis,yAxis,clickedJzonviz, selectedChart,reinit_view, downloading}){
	/* eslint-disable */

	var tableObj = ""

	if(selectedChart === 'table'){		
		tableObj = <JsonToTable setVizDatas={setVizDatas} remotedata={datas} />
	}
	
	const withCharts = (selectedChart === 'bar' || selectedChart === 'line' || selectedChart === 'scatter')
	//document.querySelector('.echarts-for-react > div:nth-child(2)').innerHTML += "<i className="">Double cliquez pour ouvrir le lien</i>"

	return (<>
		<div className="charts">
					{
					withCharts && <ReactECharts onEvents={{
					  'dblclick': handleDblClicks,
					}} option={opt({datas,xAxis,yAxis, selectedChart, downloading})}
		
						  notMerge={true}
						  lazyUpdate={true}
		
					/>
					}

					{
						selectedChart === 'table' && tableObj
					}

					{
						selectedChart === 'json' && <Jsonviewer datas={datas} />
					}
		</div>
		{downloading && <blockquote className="quote" cite={source}><label>{translate('src')}</label> : <a title={translate('src-title')} target="_blank" href={source}>{source}</a></blockquote>}
		</>
		)

}

function handleDblClicks(e){
	
	if(isValidURL(e['name'])) window.open(e['name'], '_blank').focus();

}

function new_image(imgsrc){
	return `<div class="img_field-wrap">
				<img class="img_field" title="${imgsrc}" alt="${imgsrc}" src="${imgsrc}"/>
			</div>`
}

function opt({datas,xAxis,yAxis, selectedChart, downloading}){

	const scatterMode = selectedChart === 'scatter'
	const datazoomOpt = downloading ? [] : [
	    {
	      type: 'inside',
	    },
	    {
	      type: 'slider',
	      bottom: 50,
	      backgroundColor: getComputedStyle(document.body).getPropertyValue('--main-color') + '3D' //low opacity
	    },

	  ]

	const toolboxOpt = downloading ? {} : {
	    feature: {
	      dataZoom: {
	        yAxisIndex: false
	      },
	    }
	  }

	var finalOpt = {
	  animationDuration: 500,
	  tooltip: {
	  	confine: true,
	    trigger: scatterMode ? 'item' : 'axis',
	    axisPointer: {
	      type: 'shadow'
	    },
	    textStyle: {
	      overflow: 'breakAll',
	      width: 40,
	    },
	    extraCssText: `width:auto;max-width: 50vw; white-space:pre-wrap;`,
	  },
	  toolbox: toolboxOpt,
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: 90,
	    containLabel: true
	  },
	  dataZoom: datazoomOpt,
	  xAxis: [
	    {
	      type: 'category',
	      data: getDatasAsArray(datas, xAxis),
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: yAxis,
	      type: selectedChart, //bar, line,pie,scatter
	      smooth: true,
	      data: getDatasAsArray(datas, yAxis),
	      color: getComputedStyle(document.body).getPropertyValue('--main-color')
	    }
	  ]
	}


	//scatter mode ----> image AND links handlers
	if(scatterMode){

		//if any image field found AND scatter mode ---> add this image on previewer 
		const imagekey = getFirstImageKey(datas[0])
		if(imagekey){

			finalOpt['tooltip']['formatter'] = function(param) {
				//param.seriesName for value label (Y)
				//param.data for value (Y)
				//param.name for X axis value (here, the link of image)
				const current_agg = document.querySelector('.aggregator').value
				const current_agg_display = current_agg === "count" ? "" : capitalize(document.querySelector('[value="'+current_agg+'"]').text)
				return `<strong>${current_agg_display} ${param.seriesName}</strong> : ${param.data}<br/>
						<div class="img_field-wrap">
							<img class="img_field" title="${param.name}" alt="${param.name}" src="${param.name}"/>
						</div>
				`
	        }

		}

	}


	return finalOpt

}

function capitalize(str){
  
	return str.charAt(0).toUpperCase() + str.slice(1);
  
};

function getFirstImageKey(firstData){
	return Object.keys(firstData).find(key => {
		return isImage(firstData[key]) && isValidURL(firstData[key])
	})
}

function getDatasAsArray(datas, fieldname){
	return datas.map(e => e[fieldname])
}


export default Chart