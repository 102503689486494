import html2canvas from 'html2canvas';

export const onCapture = ({selector,finalname,handleEOScreenshot}) => {
  let currentlyScreenshoting = false
  const takeScreenShot = (selector) => {
    
    const node = document.querySelector(selector)
    //console.log({currentlyScreenshoting, selector})

    if(!currentlyScreenshoting && node){

      currentlyScreenshoting = true
      console.log('doing the screenshot...')


      /************************** BEGINNING OF TECHHNICAL SCREENSHOT ************************/    
      html2canvas(node).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.target = "_blank"
        link.download = finalname+'.png'

        document.body.appendChild(link)
        link.click()
        link.remove()

      /************************** END OF TECHHNICAL SCREENSHOT ************************/    

      currentlyScreenshoting = false
      }).catch(err => {
          console.error(err)
        }).finally(() => {
          handleEOScreenshot()
        })
    }
  }

  takeScreenShot(selector)

}