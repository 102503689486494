import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import Navlayout from './components/Navlayout';
import App from './components/App';
import Docs from './components/Docs';
import Saved from './components/Saved';
import Embed from './components/Embed';
import Notfound from './components/Notfound';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
console.info('welcome :)')

root.render(
  <React.StrictMode>
  	<BrowserRouter>
  		<Routes>
  			<Route path="/" element={<Navlayout/>}>
	    		<Route index element={<App />}/> {/*Default route*/}
	    		<Route path="docs" element={<Docs />}/> 
          <Route path="saved/" element={<Saved id_viz_test={'19316566'}/>}/>
	    		<Route path="saved/:id_viz" element={<Saved />}/>
          <Route path="embed/:id_viz" element={<Embed />}/>
	    		<Route path="*" element={<Notfound />}/>
    		</Route>
    	</Routes>
    </BrowserRouter>
  </React.StrictMode>
);
/*root.render(
    <App />
);

*/
