import { cleanIt } from './JsonToTable'
import { useState } from 'react'

function JsonToHeader({column, handleSorting }){

	const [sortField, setSortField] = useState("");
	const [order, setOrder] = useState("asc");

	const handleSortingChange = (accessor) => {
		const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
		setSortField(accessor);
		setOrder(sortOrder);
		handleSorting(accessor, sortOrder);



	};

	// get table heading data
	const ThData =()=>{
		return column.map((header)=>{
			const accessor = header
			const iconheader = sortField === accessor && order === "asc" ? "up"
						      : sortField === accessor && order === "desc" ? "down"
						      : "default"

			return <th key={accessor} className={iconheader} onClick={() => handleSortingChange(accessor)}>{cleanIt(header)}</th>
	 	})
	}


	return 	<thead className="json-to-table-head">
	         <tr>{ThData()}</tr>
	        </thead>
}


function handleSortingChange(header){
	console.log(header)
}

export default JsonToHeader