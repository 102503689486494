import JsonToHeader from './JsonToHeader'
import JsonToBody from './JsonToBody'
import Pagination from './Pagination'
import { useState, useEffect } from 'react'

function JsonToTable({remotedata, setVizDatas}){

	const [maxRows, setMaxRows] = useState(100)
	const [localTableDatas, setLocalDatas] = useState(remotedata.slice(0,maxRows-1))
	const [currentPage, setCurrentPage] = useState(1)
	const [minmax, setMinmax] = useState([1,maxRows])

	const handleSorting = (sortField, sortOrder) => {
		/*
		console.log({sortField})
		console.log({sortOrder})
		*/
		if (sortField) {
			const sorted = [...remotedata].sort((a, b) => {
				if (a[sortField] === null) return 1;
				if (b[sortField] === null) return -1;
				if (a[sortField] === null && b[sortField] === null) return 0;
				return (
					(a[sortField] || "").toString().localeCompare((b[sortField] || "").toString(), "en", {
					numeric: true,
					}) * (sortOrder === "asc" ? 1 : -1)
				);
			});
			setVizDatas(sorted);
			//console.log('after sort',{localTableDatas})
		}
	};

	const noth = () => {}
	const handlePagination = (pageNumber) => {
		const MODETEST = false
		const log = MODETEST ? console.log : noth

		log('\n\n\n\n')
		setCurrentPage(pageNumber)

		log('initial length', remotedata.length)
		log({currentPage})

		const firstIndex = (currentPage - 1) * maxRows
		log({firstIndex})

		const lastIndex = (currentPage * maxRows) - 1 
		log({lastIndex})

		setMinmax([firstIndex+1, lastIndex+1])

		const finallocaldatas = remotedata.slice(firstIndex,lastIndex)
		setLocalDatas(finallocaldatas) //todo

		log('final cut datas',finallocaldatas.length+1)

		return finallocaldatas
	}


	//always SLICE at the beginning
	//when page changes => handle it
	useEffect(() => {
		handlePagination(currentPage)
	}, [remotedata, currentPage])


	// get table column
	const column = localTableDatas[0] ? Object.keys(localTableDatas[0]) : [];


	return (<>
			<div className="table-wrap">
	      <table  className="json-to-table">
	      	<JsonToHeader column={column} handleSorting={handleSorting} />
	        <tbody>
	        	<JsonToBody data={localTableDatas} column={column}/>
	        </tbody>
	       </table>
	   </div>
	   <Pagination datalength={remotedata.length} maxRows={maxRows} setMaxRows={setMaxRows}
	   			   min={minmax[0]} max={minmax[1]}
	   			   handlePagination={handlePagination} currentPage={currentPage}
	   	/>
	   </>
	)
}


export function cleanIt(obj) {

	if(typeof(obj) === 'string') return obj
	
    return  JSON.stringify(obj, null, 2);
	
}


export function randomkey() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    ((c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & 15)) >> (c / 4)).toString(16)
  );
}










export default JsonToTable