import Logo from './Logo'
import Language from './Language'
import Light from './Light'

function Navbar({clickedJzonviz, setClicked, lang, setLang, translate}){

	return (
			<div className="top-wrap">
				<Logo/> 

				<div className="top-btns">
					<Language defaultValue={lang} setLang={setLang} translate={translate} clickedJzonviz={clickedJzonviz} setClicked={setClicked}/>
					<Light translate={translate}/>
				</div>
	        </div>
        )
}

export default Navbar