import { translate } from './Translations'
import { showAlert } from './Snackbar'
import { useState, useEffect } from 'react'
import Pagination from './Pagination'

function Jsonviewer({datas}){

	const [maxRows, setMaxRows] = useState(100)
	const [localTableDatas, setLocalDatas] = useState(datas.slice(0,maxRows-1))
	const [currentPage, setCurrentPage] = useState(1)
	const [minmax, setMinmax] = useState([1,maxRows])

	const noth = () => {}
	const handlePagination = (pageNumber) => {
		const MODETEST = false
		const log = MODETEST ? console.log : noth

		log('\n\n\n\n')
		setCurrentPage(pageNumber)

		log('initial length', datas.length)
		log({currentPage})

		const firstIndex = (currentPage - 1) * maxRows
		log({firstIndex})

		const lastIndex = (currentPage * maxRows) - 1 
		log({lastIndex})

		setMinmax([firstIndex+1, lastIndex+1])

		const finallocaldatas = datas.slice(firstIndex,lastIndex)
		setLocalDatas(finallocaldatas) //todo

		log('final cut datas',finallocaldatas.length+1)

		return finallocaldatas
	}


	//always SLICE at the beginning
	//when page changes => handle it
	useEffect(() => {
		handlePagination(currentPage)
	}, [datas, currentPage])





	return <>
			<div className="simplejson table-wrap">
				<div>
					<button onClick={copycontent}><span>{translate('copy')}</span></button>
				</div>
				<div>
					<pre className="jsoncontent" id="content">
						{JSON.stringify(localTableDatas, null, 2)}
					</pre>
				</div>
			</div>
				   <Pagination datalength={datas.length} maxRows={maxRows} setMaxRows={setMaxRows}
	   			   min={minmax[0]} max={minmax[1]}
	   			   handlePagination={handlePagination} currentPage={currentPage}
	   	/>
	   	</>
}

function copycontent(){
	showAlert(translate('copied'))
	navigator.clipboard.writeText(document.getElementById('content').innerText)
}



export default Jsonviewer