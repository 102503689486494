import MenuItem from './MenuItem'
import {useState} from 'react'
import { onCapture } from './Screenshot'
import { sendToServer } from './Saving'
//import { useNavigate } from "react-router-dom"


function Moremenu({updateSavedDatas,vizDatas, translate,reinit_view,selectedChart,showAlert,setLoading,downloading,setDownloading
					,currentURL, remoteParams }){
	const [morebtnsOpen, setOpenMore] = useState(false)
    //const navigate = useNavigate();

	const handleMoreMenu = (e) => {
		setOpenMore(!morebtnsOpen)
	}

	const noMenu = (e) => {
		e.stopPropagation()
		e.preventDefault()
		console.log({'clicked id':e.target.id})
		if(e.target.id !== "menuMore"){
			setOpenMore(false)	
		}else{	
			handleMoreMenu(e)
		}
	}

	const saveHandler = () => {
		updateSavedDatas(vizDatas)

		const search = window.location.search.substring(1) //get rid of '?'
		console.log({search})
		
		let tobe_saved = {}

		//very first time it's being saved
		if(!window.location.href.includes('saved')){
			let temp = decodeURI(search)
			//console.log('temp1: ',temp)
			temp = temp.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"')
			//console.log('temp2: ',temp)
			temp = decodeURIComponent(temp)
			//console.log('temp3: ',temp)
			tobe_saved = JSON.parse('{"' + temp + '"}');
		
		//saving existant viz
		}else{
			//keep all previous params
			tobe_saved = remoteParams

			//overwrite all newly added params
			if(search){
				const more_tobe_saved = JSON.parse('{"' + decodeURIComponent(decodeURI(search)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
				Object.keys(more_tobe_saved).forEach((key) => {
					tobe_saved[key] = more_tobe_saved[key]
				})				
			}

			tobe_saved['url'] = currentURL
		}
		

		console.log({tobe_saved})
		const url_data_source = currentURL // it's a state of App.js

		sendToServer({tobe_saved, url_data_source}).then((data) => {
			const firstData = data[0]
			console.log('returned data from server : ',firstData)

			const id_viz=firstData['id_viz']
			showAlert(translate('saving') + ' : ' + id_viz)


			//go to saved/:id_viz
			//navigate('/saved/'+id_viz)
		})


	}

	const embedHandler = () => {
		showAlert(translate('embed'))
	}

	const capitalize = (string) => {
	  return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const downloadViz = (e) => {

      	document.getElementById('topping').style.display="block"

		setLoading(true)
		setDownloading(true)

		const selectorBtns = '.viz-btn-wrap, .charttype-wrap'
		const titleElement = document.querySelector('.tooltip h3')
		const charts = document.querySelector('.charts')
		
		//duplicate node of title => rearrange it without specific html elements
		const newTitleNode = titleElement.cloneNode(true);
		const titleText = capitalize(Array.from(newTitleNode.childNodes).map(function(e,i){
			if(i === 0 || (e.className || '').includes("remover")) return '' //always ignore first sentence AND cross to delete
			if(e.localName === "select") return translate(document.querySelector('.aggregator').value)			
			return nosymbol(e.innerText || e.textContent ||  e.value)
		}).join(''))

		newTitleNode.innerHTML = titleText

		//add temporary title, hide all buttons, change chart height
		titleElement.style.display = "none"
		titleElement.parentNode.appendChild(newTitleNode);
		toggleDisplay(selectorBtns,'none')
		charts.style.height = '90%'



		//wait a little
		setTimeout(function(){

			//function that handles end of the screenshot
			const handleEOScreenshot = () => {
				//remove temporary title, show back all buttons
				newTitleNode.remove()
				titleElement.style.display = ""
				toggleDisplay(selectorBtns,'')
				charts.style.height = ''
				setLoading(false)
				setDownloading(false)

			}

			//capture viz
			onCapture({
						'selector':'.dataviz',
						'finalname': titleText + ' ' + localNow(),
						'handleEOScreenshot': handleEOScreenshot
					})


			document.getElementById('topping').style.display="none"

		}, 1000)
	
	


	}


	const nosymbol = (str) => {
		return str ? str.replaceAll('▲','') : ""
	}

	const localNow = () => {
		return (new Date()).toLocaleString().replaceAll('/','-').replaceAll(':','-')
	}

	/*
	const ismobile = () => {
		return window.screen.width < 400
	}
	*/

	const toggleDisplay = (selector,value) => {
		Array.from(document.querySelectorAll(selector)).map(e => e.style.display = value)
	}


	return 	<div id="menuMore" className="viz-btn-wrap more-btns" onClick={handleMoreMenu} onBlur={noMenu}>

				{morebtnsOpen && <ul className="more-btns-wrap">

					<MenuItem content={translate(18)}
							className="nofilter reinit"
							clickHandler={reinit_view}
							/>

					<MenuItem content={translate('save')}
							className="save"
							clickHandler={saveHandler}
							/>

					{/*['bar','scatter','line'].indexOf(selectedChart) >= 0 && !ismobile() &&*/
						['bar','scatter','line'].indexOf(selectedChart) >= 0 &&
						<MenuItem content={translate(34)}
							className="download"
							clickHandler={downloadViz}
							/>
                    }
					
					<MenuItem content={translate('embed')}
							className="embed"
							clickHandler={embedHandler}
							/>
				
				</ul>}
			</div>
}

export default Moremenu