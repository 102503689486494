import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'
import { TranslationsState } from './Translations'

function Navlayout(){

	const context = TranslationsState() //lang, setLang, translate
	const { lang, setLang, translate } = context
	
	return <>
			<Navbar lang={lang}
					setLang={setLang}
					translate={translate} 
			/>
			<Outlet context={context} />
		   </>
}

export default Navlayout