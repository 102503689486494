import '../styles/Sidebar.css'
//import { useLayoutEffect  } from 'react'

function Sidebar({children, viewFields, setViewFields}){

	return 	<div>
				<input defaultValue={viewFields} onChange={e => setViewFields(e.target.checked) } className="hidden-input" type="checkbox" id="toggleSidebar" name="toggleSidebar"/>
				<label htmlFor="toggleSidebar"></label>
				<div className="sidebar">
					{children}
				</div>
			</div>
}

export default Sidebar