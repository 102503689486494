function Logo(){
	
	return	<a className="nodecoration" href="/" title="JSONVIZZ"><div className="jsonviz-logo-wrap">
				<strong draggable="true" className="logo" title="JsonVizz">
					<span>Json</span>
					<span>Vizz</span>
				</strong>
			</div>
			</a>
}

export default Logo