import { useOutletContext } from 'react-router-dom'

function Notfound(){
	const { translate } = useOutletContext()
	return <>
			<h1>{translate(404)}</h1>
			<a className="nodecoration" href="/">{translate('back')}</a>
			</>
}

export default Notfound