import '../styles/Filterpannel.css'

function Filterpannel({translate, filtername, filterType, values, setFilterValue, setFilter, filterValue, handleAddParameter, handleRemoveParameter}){

	const handleFilterChange = (e) => {
 
		setFilterValue(e.target.value)
		handleAddParameter('filter['+filtername+']',e.target.value)
	}	

	const noValue = () => {
		return filtername ? '('+ translate(7) +')' : (isMobileMode() ? translate('filter-mobile') : translate('filter') )
	}
	
	// eslint-disable-next-line
	const isNumberType = () => {
		return filterType === translate(6)[4] // (numbers) => slider
	}

	return <div className="filter-wrap">
			{filtername && <label className="filtername">{filtername}</label> }
			<select id="filterValues" className="filterpannel" onChange={handleFilterChange} value={filterValue} > 
				<option value="">{noValue()}</option>
				{   
					values.map(function (e) {
						return e.toString() && <option value={e.toString()} key={e.toString()}>{e.toString()}</option>
					})
				}
			</select>

			{filtername && <button title={translate(14)} className="nofilter reinit" onClick={() => {
				setFilter('')
				setFilterValue('')
				handleRemoveParameter('filter['+filtername+']')
			}}></button>}

			</div>

}

function isMobileMode(){
	return window.innerWidth <= 500
}


export default Filterpannel