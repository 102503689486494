import '../styles/Tooltip.css'
import '../styles/Groupfield.css'
import Aggregator from './Aggregator'

function Tooltip({ wholeDatasMode, selectedChart, currentSortByField, setSortByField, translate, onlyOneField, clickedJzonviz, setClicked, aggregator, setAggregator, setFilterValue, filtername, filterValue
					, handleRemoveParameter, handleAddParameter
					}){
	//console.log('tooltip',wholeDatasMode)
	if(wholeDatasMode) {
		return <h1 className="tooltip">{translate(10)}</h1>
	} else {
		return Object.keys(clickedJzonviz).length === 0 
			? helper(translate)
			: content({selectedChart, currentSortByField, setSortByField, translate, onlyOneField, clickedJzonviz,setClicked,aggregator,setAggregator, setFilterValue, filtername, filterValue, handleRemoveParameter, handleAddParameter})
	
	}
	
}


function numbers_str(translate){ 
	return translate(6)[4]
}

function dimension_str(translate){ 
	return translate(6)[1]
}


function helper(translate){
	return <div className="tooltip">
			<h3><i>{translate(11)}<strong className="num highlight">{translate(6)[4]}</strong>{translate(12)}<strong className="dim highlight">{translate(13)}</strong></i></h3>
        	</div>
}

function content({selectedChart, currentSortByField, setSortByField, translate, onlyOneField,clickedJzonviz,setClicked,aggregator,setAggregator, setFilterValue, filtername, filterValue, handleRemoveParameter, handleAddParameter}){
	var res = ''

	/*this graph shows AGG of FIELD*/
	var prefixTitle = selectedChart === 'table' ? translate('table') : translate('chart')
	prefixTitle += translate(15) 

	// eslint-disable-next-line 
	const orderbyfield = currentSortByField ? currentSortByField.split(' ')[0].toString() : "" //todo : display which field is used to order by
	//console.log({orderbyfield})

	if(onlyOneField){
		const current_field = Object.keys(clickedJzonviz)[0]	
		const className = current_field === dimension_str(translate) ? 'dim' : 'num'
		res =   <h3>
					{prefixTitle}
					{aggregatorLocal({translate,onlyOneField,aggregator,setAggregator,clickedJzonviz,handleAddParameter})}
					{translate(16)}
					<strong id={clickedJzonviz[current_field]} className={className+" highlight"}>
						<FormatClickedJsonVizz clickedJzonviz={clickedJzonviz} field={current_field} />
						{discardJsonField({clickedJzonviz,translate,setClicked,handleRemoveParameter})}
					</strong>
					{filterTooltip({setFilterValue, translate, filtername, filterValue, handleRemoveParameter})}
					
				</h3>
	} else{

		res = <h3>
				{prefixTitle}
				{aggregatorLocal({translate,onlyOneField,aggregator,setAggregator,clickedJzonviz,handleAddParameter})}
				{translate(16)}
				<strong id={clickedJzonviz[numbers_str(translate)]} className="num highlight">
					<FormatClickedJsonVizz clickedJzonviz={clickedJzonviz} field={numbers_str(translate)} />
					{sortByThis({translate,setSortByField,handleAddParameter})}
					{discardJsonField({clickedJzonviz,translate,setClicked,handleRemoveParameter})}
				</strong>

				{translate(26)}
				<strong id={clickedJzonviz[dimension_str(translate)]} className="dim highlight">
					<FormatClickedJsonVizz clickedJzonviz={clickedJzonviz} field={dimension_str(translate)} />
					{sortByThis({translate,setSortByField,handleAddParameter}) }  
					{discardJsonField({clickedJzonviz,translate,setClicked,handleRemoveParameter})}
				</strong>
				{filterTooltip({setFilterValue, translate, filtername, filterValue, handleRemoveParameter})}
			</h3>

	}

	return <div className="tooltip">{res}</div>
}

function filterTooltip({setFilterValue, translate, filtername, filterValue, handleRemoveParameter}){
	if(filtername && filterValue){
		return <span>{surroundWithSpaces(translate("where"))}
					<strong className="filtervalue highlight">
						<span className="important">{filtername}</span>
						{surroundWithSpaces(translate('equals to'))}
						<span className="important">{filterValue}</span>
						<span onClick={() => {
									setFilterValue('')
									handleRemoveParameter('filter['+filtername+']')
								}
							} title={translate('no-current-filter')} class="fieldbtn remover"></span>
					</strong>
				</span>
	}else {
		return <></>
	}
}

function surroundWithSpaces(text){
	return ' ' + text + ' '
}

function FormatClickedJsonVizz({clickedJzonviz,field}){
	return <span className="tooltipfield">{clickedJzonviz[field]}</span>
}

function sortByThis({translate,setSortByField,handleAddParameter}){
	return <span className="fieldbtn" title={translate(29)} onClick={(e) => {
		e.preventDefault()
		e.stopPropagation()
		const fieldtosortwith = e.target.parentNode.id + '^asc' 
		
		console.log({fieldtosortwith})
		handleAddParameter('order',fieldtosortwith)
		setSortByField(fieldtosortwith) 

	}}>▲</span>
}
// order : ⭜ vs ⭝

function aggregatorLocal({translate,onlyOneField,aggregator,setAggregator,clickedJzonviz,handleAddParameter}){
	return <Aggregator translate={translate} defaultValue={aggregator} onChange={(e) => {
		setAggregator(e.target.value)
		handleAddParameter('agg',e.target.value)
	}} onlyOneField={onlyOneField} clickedJzonviz={clickedJzonviz}/>				
}

function discardJsonField({clickedJzonviz,translate,setClicked,handleRemoveParameter}){
	return <span title={translate(27)} className="fieldbtn remover" onClick={(e) => {
		e.preventDefault()
		e.stopPropagation()

		const fieldtoremove = e.target.parentNode.id
		const temp = { ...clickedJzonviz}

		const keytoremove = Object.keys(clickedJzonviz).map(key => clickedJzonviz[key]===fieldtoremove ? key : null ).filter(e => e)[0]		


		delete temp[keytoremove]	
		handleRemoveParameter()	
		setClicked(temp)


		//dim or num
		const removed_param_name = keytoremove === translate(6)[1] ? 'dim'
								   : keytoremove === translate(6)[4] ? 'num'
								   : 'todo'
		handleRemoveParameter(removed_param_name)


		//no more agg if 1 jsonfield missing
		handleRemoveParameter('agg')

	}}></span>
}


export default Tooltip