const supabase = window.supabase //from common elements js

function Saving({tobe_saved}){
	return <></>
}

async function upsertDatas(tablename,params) {

	const { data, error } = await supabase
	  .from(tablename)
	  .upsert(params)
	  .select()

	return { data, error }
}


export const sendToServer = async ({tobe_saved, url_data_source,}) => {
	
	console.log('tobe_saved=',tobe_saved)

	let datas = {
					'id_viz': '19316566',
					'url_data_source': url_data_source,
					'params': tobe_saved
				}

	return await upsertDatas('jsonvizz', datas).then((res) => {
					console.log('received after save : ',res)
					return res.data
				})

}

export default Saving