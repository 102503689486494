import '../styles/Snackbar.css'
import {useState} from 'react'

var count, setCount

function Snackbar(){
	[count,setCount] = useState(0)

	return <span id="toast"></span>
}

export function showAlert(content) {
  setCount(count+1)

  var x = document.getElementById("toast");
  x.innerText = content;
  x.className = "show";

  setTimeout(function(){

  	setCount(count-1)
  	if(count === 1){
  		setCount(0)
  		x.className = x.className.replace("show", "");	
  	} 
  }, 3000);
}

export default Snackbar